<template>
<div class="productSeries">
  <CompanyProfileBanner :index="'2'" :imageUrl="imageUrl"></CompanyProfileBanner>
  <BreadcrumbAndSubtag
    :tabList="tabList"
    :activeNowPath="activeNowPath !== '' ? activeNowPath : '3-1'"
    @changeActiveTabFun="changeActiveTabFun"
  ></BreadcrumbAndSubtag>
  <div v-if="activeNowPath === '3-1'" class="TechnicalOverview">
    <el-empty v-if="overviewImg === '' && overviewText.length === 0" description="敬请期待"></el-empty>
    <template v-else>
      <img class="firmImg" :src="overviewImg" />
      <div class="introText">
        <p v-for="(text, index) in overviewText" :key="index">
          {{ text }}
        </p>
      </div>
    </template>
  </div>
  <div v-else-if="activeNowPath === '3-2'" class="productContent">
    <div class="contents">
      <div class="productList">
        <ul v-if="listData.length > 0">
          <li
            v-for="list in listData.slice((currPage-1)*pageSize,currPage*pageSize)"
            :key="list.id"
            @click="handleClickProductItemFun(list)"
          >
            <div class="productItem">
              <div class="imgs">
                <img :src="list.imageThumbnail" />
                <div class="mask">
                  <p>{{ list.title }}</p>
                </div>
              </div>
              <a>{{ list.title }}</a>
            </div>
          </li>
        </ul>
        <el-empty v-else description="产品数据为空"></el-empty>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[6,9,12]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="listData.length"
      ></el-pagination>
    </div>
  </div>
  <div v-else class="customerSays">
    <el-empty v-if="imagText.length === 0" description="敬请期待"></el-empty>
    <template v-else>
      <el-row :gutter="24" class="rowContent" v-for="(items,index) in imagText" :key="index">
        <template v-if="(index+1) % 2 === 0">
          <el-col :span="12" class="firmImg">
            <img  :src="items.imgUrl" />
          </el-col>
          <el-col :span="12" class="introText">
            <p v-for="(text, keys) in items.text" :key="keys">
              {{ text }}
            </p>
          </el-col>
        </template>
        <template v-else>
          <el-col :span="12" class="introText">
            <p v-for="(text, indexs) in items.text" :key="indexs">
              {{ text }}
            </p>
          </el-col>
          <el-col :span="12" class="firmImg">
            <img  :src="items.imgUrl" />
          </el-col>
        </template>
      </el-row>
    </template>
  </div>
  <footerMain></footerMain>
</div>
</template>

<script>
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import footerMain from '@/components/footerMain'
import {mapGetters} from "vuex";

export default {
  name: "productSeries",
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain
  },
  data() {
    return {
      imageUrl: '',
      pageId: '',
      overviewImg: '',
      overviewText: [],
      listData: [],
      pageSize: 6,
      currPage: 1,
      tabList: [
        {key: '3-1', name: '技术概况'},
        {key: '3-2', name: '产品系列'},
        {key: '3-3', name: '客户说'},
      ],
      activeNowPath: '',
      saysImg: '',
      saysText: [],
      imagText: []
    }
  },
  computed: {
    ...mapGetters(['secondaryRoutingIndex'])
  },
  watch: {
    secondaryRoutingIndex: {
      handler(n,o) {
        const checkPath = (url) => {
          return [
            "3-1",
            "3-2",
            "3-3",
          ].includes(url);
        }
        if(checkPath(n)) {
          this.activeNowPath = n
        } else {
          this.activeNowPath = '3-1'
          this.$store.commit('SET_CURRENT_ROUTE', '/productSeries')
          this.$store.commit('SET_SECONDARY_ROUTING_INDEX', '3-1')
        }
        this.loadBannerFun()
      },
      immediate: true
    },
  },
  created() {
    this.loadBannerFun()
    this.loadResourceOfPageFun();
    this.getArticleListFun()
  },
  methods: {
    changeActiveTabFun(val) {
      this.activeNowPath = val
      if(val === '3-2') {
        this.getArticleListFun()
      }
      this.loadBannerFun()
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', val)
    },
    handleSizeChange(val) {// 处理每页显示条数的变化
      this.pageSize = val;
    },
    handleCurrentChange(val) {// 处理当前页面的变化
      this.currPage = val;
    },
    handleClickProductItemFun(list) {
      this.$store.commit('SET_ARTICLES_ID', list.id)
      this.$router.push({
        path: '/productAndSolutionDetails',
        query: {data: btoa(encodeURIComponent(
            JSON.stringify({type: 'product', pageId: this.pageId})
          )),
        }
      })
    },
    loadBannerFun() {
      api.getResourceOfPage({pageId: this.loadListDataFun(this.activeNowPath), locationType: 1}).then((res) => {
        if(res.code === 100) {
          if(res.data[0].images.length > 0) {
            this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
          }
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    loadListDataFun(key) {
      switch (key) {
        case '3-1':
          return 23;
        case '3-2':
          return 8;
        case '3-3':
          return 24;
        default:
          return 23;
      }
    },
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 22, includeSub:1, locationType: 3}).then((res) => {
        if(res.code === 100) {
          this.imagText = []
          let _texts = [],_images=[]
          res.data.forEach(item => {
            if(item.pageId === 23) {
              this.overviewImg = resAddressPrefix + item.images[0].imageUrl
              this.overviewText = item.pageText ? item.pageText.split('&&') : []
            } else if(item.pageId === 24) {
              _texts = item.pageText ? item.pageText.split('&&') : [];
              item.images.forEach(d => {
                _images.push(resAddressPrefix + d.imageUrl)
              })
            }
          });
          let _texts1 = _.chunk(_texts, 2);
          if(_texts1.length > 0) {
            _texts1.forEach((d, index) => {
              this.imagText.push({text: d, imgUrl: _images[index]})
            })
          } else {
            if(_images.length>0) {
              _images.forEach(l => {
                this.imagText.push({text: [], imgUrl: l})
              })
            }
          }
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getArticleListFun() {
      api.getArticleList({pageId: 8, summaryFlag: 1}).then((res) => {
        if(res.code === 100) {
          this.pageId = res.data[0].pageId
          this.listData = res.data[0].articles
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.productSeries {
  .TechnicalOverview{
    width: 1200px;
    height: 440px;
    display: flex;
    margin: 2em auto;
    ::v-deep .el-empty {
      flex: 1;
    }
    .firmImg {
      flex: 1;
      width: 90%;
      height: 90%;
    }
    .introText {
      flex: 1;
      padding-left: 20px;
      p {
        color: #555;
        line-height: 1.8;
        text-indent: 2em;
        font-size: 16px;
        text-align: left;
      }
    }
  }
  .productContent {
    margin: auto;
    display: table;
    vertical-align: middle;
    .contents {
      width: 1200px;
      height: 560px;
      padding: 2em 0;
      margin: 2em auto;
      display: table-cell;
      vertical-align: middle;
      .productList {
        width: 100%;
        height: 100%;
        ul {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            float: left;
            text-align: left;
            padding: 10px;
            &:nth-child(1),
            &:nth-child(3n+1), {
              padding-left: 0;
            }
            &:nth-child(3n) {
              padding-right: 0;
            }
            .productItem {
              background-color: #f8f8f8;
              transition: none 0.4s linear;
              -webkit-transition: none 0.4s linear;
              -moz-transition: none 0.4s linear;
              box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
              .imgs {
                cursor: pointer;
                position: relative;
                overflow: hidden;
                img {
                  width: 386px;
                  height: 230px;
                  display: block;
                  transition: all 0.3s ease-out 0s;
                }
                .mask {
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.28);
                  position: absolute;
                  top: 0;
                  left: 0;
                  transition: 0.3s linear;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  opacity: 0;
                  p {
                    color: #FFFFFF;
                    font-size: 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                    &:before {
                      content: '';
                      position: absolute;
                      top: 42%;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 60%;
                      height: 1px;
                      background: #fff;
                      transition: all .5s ease;
                    }
                    &:after {
                      content: '';
                      position: absolute;
                      bottom: 42%;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 60%;
                      height: 1px;
                      background: #fff;
                      transition: all .5s ease;
                    }
                  }
                }
                &:hover {
                  img {
                    transform: scale(1.1,1.1);
                  }
                  .mask {
                    opacity: 1;
                    p {
                      &:before,
                      &:after {
                        width: 1rem;
                      }
                    }
                  }
                }
              }
              a {
                line-height: 60px;
                padding: 0 10px;
                font-size: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      ::v-deep .el-pagination {
        padding: 0 0 20px 0;
      }
    }
  }
  .customerSays {
    width: 1200px;
    margin: 2em auto;
    //::v-deep .el-empty {
    //  flex: 1;
    //}
    .rowContent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1em;
      .firmImg,
      .introText {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .firmImg {
        img {
          max-width: 100%;
          height: auto;
          border: solid #dedede;
        }
      }
      .introText {
      //  padding-right: 20px;
        p {
          color: #555;
          line-height: 1.8;
          text-indent: 2em;
          font-size: 16px;
          text-align: left;
        }
      }
    }

  }
}
</style>
